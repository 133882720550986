import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Helmet from "react-helmet"

import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';


const Cookies = ({ data }) => (
  <>
  <Helmet
  title={data.wordpressPage.acf.cookies_title_seo} 
  titleTemplate={data.wordpressPage.acf.cookies_title_seo}
  meta={[
    {
      name: `description`,
      content: data.wordpressPage.acf.cookies_description_seo, 
    },
    {
      property: `og:title`,
      content: data.wordpressPage.acf.cookies_title_seo,
    },
    {
      name: `thumbnail`,
      content: data.wordpressPage.acf.cookies_img_seo.localFile.url, 
    },
    {
      property: `og:image`,
      content: data.wordpressPage.acf.cookies_img_seo.localFile.url, 
    },
    {
      property: `og:description`,
      content: data.wordpressPage.acf.cookies_description_seo,
    },
    {
      property: `og:type`,
      content: `website`,
    },
  ]}
/>

    <div className="small-banner-content">
    <div style={{backgroundImage: `url(${data.wordpressPage.acf.cookies_banner_img.localFile.url})`}} className="background-small"></div>
          <div className="text-on-banner">  

          <ScrollAnimation animateOnce='true' animateIn='fadeInUp' delay='100' duration='0.5'>
          <h1 className='smallbanner-title'>{data.wordpressPage.acf.cookies_title}</h1>
          </ScrollAnimation>
      
        </div>
        <div className='right-bottom-curve-cont'>
          <div className="right-bottom-curve"></div>
        </div>
        </div>


<Layout lang="pl" pageInfo={{ pageName: "cookies" }}>
    <div className='cookies'>
      <Container>
        <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.cookies_content }}></div>
      </Container>
    </div>
</Layout> 
</>
)

export default Cookies
 export const query = graphql`
 query hyeqweqwtrhy {
  wordpressPage(title: {eq: "Cookies"}) {
    acf {
      cookies_title_seo
      cookies_description_seo
      cookies_img_seo {
        localFile {
          url
        }
        alt_text
      }
      cookies_banner_img {
        localFile {
          url
        }
        alt_text
      }
      cookies_title
      cookies_content
    }
  }
}



 `
